import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({
  padding: '80px 0',

  [theme.breakpoints.down('tablet')]: {
    padding: '60px 0',
  },

  [theme.breakpoints.down('mobileL')]: {
    display: 'none',
  },
}))

export const Inner = styled.div(({theme}) => ({
  maxWidth: 670,
}))
