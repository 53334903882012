import React from 'react'
// import PropTypes from 'prop-types'
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {Wrapper, Block} from './ContactMapCard.styles'
import Text from '../Text'

const containerStyle = {
  width: '237px',
  height: '237px',
}

const ContactMapCard = ({title, street, postCode, tel, fax, email, mapKey, options, ...rest}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  return (
    <Wrapper {...rest}>
      <Block padding mobHeightAuto>
        <Text content={title} size="titleXs" mb={19} uppercase />
        <Text content={street} mb={5} />
        <Text content={postCode} mb={5} />
        <Text content={tel} mb={5} />
        <Text content={fax} mb={5} />
        <Text content={email} mb={0} />
      </Block>
      <LoadScript googleMapsApiKey="AIzaSyCqimRXFRD4QSe3YjMjTJJc5VUk3bv8_R0" language={language}>
        <GoogleMap mapContainerStyle={containerStyle} center={options.center} zoom={options.zoom}>
          <Marker position={options.center} />
        </GoogleMap>
      </LoadScript>
    </Wrapper>
  )
}

ContactMapCard.propTypes = {}
ContactMapCard.defaultProps = {}

export default ContactMapCard
