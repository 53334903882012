import React, {useEffect} from 'react'
import SEO from 'components/SEO'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {graphql} from 'gatsby'
import HeroWithoutSlider from '../components/HeroWithoutSlider'
import ContactContent from '../components/ContactContent'
import ContactVideoConference from '../components/ContactVideoConference'
import Header from "../components/Header";
import Layout from "../components/Layout";

export default function ContactPage({
  data,
  location,
  pageContext: {
    breadcrumb: {crumbs},
  },
}) {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()


  useEffect(()=>{
    if (typeof window !== 'undefined' && data.strapiContactPage.en_disabled) {
      changeLanguage('de')
    }
  },[])
  return (
    <Layout dataLocales={data} location={location}>
    <Header dataLang={data.strapiContactPage.en_disabled} />
      <SEO
        title={data.strapiContactPage.seo_title[language]}
        description={data.strapiContactPage.seo_description[language]}
      />
      <HeroWithoutSlider
        title={t('sections.hero.contactTitle')}
        crumbs={crumbs}
        crumbLabel={t('sections.hero.contactTitle')}
      />
      <ContactContent />
      <ContactVideoConference />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactQuery ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    strapiContactPage {
      en_disabled
      seo_title {
        de
        en
      }
      seo_description {
        de
        en
      }
    }
  }
`
