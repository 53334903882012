import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {Wrapper, Inner} from './ContactVideoConference.styles'
import Text from '../Text'
import ContentContainer from '../ContentContainer'
import VideoConferenceIcons from '../VideoConferenceIcons'

const ContactVideoConference = ({children, ...rest}) => {
  const {t} = useTranslation()
  return (
    <Wrapper {...rest}>
      <ContentContainer>
        <Inner>
          <Text
            content={t('sections.contact.videoConference.title')}
            size="titleSm"
            mb={20}
            uppercase
          />
          <Text content={t('sections.contact.videoConference.text')} mb={20} fontLato />
          <VideoConferenceIcons />
        </Inner>
      </ContentContainer>
    </Wrapper>
  )
}

ContactVideoConference.propTypes = {}
ContactVideoConference.defaultProps = {}

export default ContactVideoConference
