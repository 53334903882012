import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({
  padding: '80px 0',
  background: theme.palette.primary.bg,

  [theme.breakpoints.down('tablet')]: {
    padding: '60px 0',
  },
}))

export const InnerWrapper = styled.div(({theme}) => ({
  maxWidth: 770,
  display: 'flex',
  flexWrap: 'wrap',
}))
