import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({
  marginRight: 29,
  marginBottom: 29,

  '&:last-of-type': {
    marginRight: 0,
  },

  [theme.breakpoints.down('mobileL')]: {
    width: '100%',
    marginRight: 0,
  },
}))

export const Block = styled.div(({theme, padding, mobHeightAuto}) => ({
  padding: padding ? '30px 20px 20px 20px' : 0,
  width: 237,
  height: 237,
  background: theme.palette.common.white,

  [theme.breakpoints.down('mobileL')]: {
    width: '100%',
    height: mobHeightAuto ? 'auto' : 335,
  },
}))
