import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {InnerWrapper, Wrapper} from './ContactContent.styles'
import Text from '../Text'
import ContentContainer from '../ContentContainer'
import ContactMapCard from '../ContactMapCard'

const ContactContent = ({children, ...rest}) => {
  const {t} = useTranslation()
  const hamburgLocation = {
    center: {
      lat: 53.551590310259535,
      lng: 9.989782636735235,
    },
    zoom: 11,
  }
  const munchenLocation = {
    center: {
      lat: 48.14531512771509,
      lng: 11.595279236143764,
    },
    zoom: 11,
  }
  const gottingenLocation = {
    center: {
      lat: 51.54108298069491,
      lng: 9.949317736522717,
    },
    zoom: 11,
  }
  return (
    <Wrapper {...rest}>
      <ContentContainer>
        <InnerWrapper>
          <Text content={t('sections.contact.text')} mb={40} maxWidth={670} fontLato />
          <ContactMapCard
            title={t('sections.contact.card1.title')}
            street={t('sections.contact.card1.street')}
            postCode={t('sections.contact.card1.postCode')}
            tel={t('sections.contact.card1.tel')}
            fax={t('sections.contact.card1.fax')}
            email={t('sections.contact.card1.email')}
            mapKey="AIzaSyCqimRXFRD4QSe3YjMjTJJc5VUk3bv8_R0"
            options={hamburgLocation}
          />
          <ContactMapCard
            title={t('sections.contact.card2.title')}
            street={t('sections.contact.card2.street')}
            postCode={t('sections.contact.card2.postCode')}
            mapKey="AIzaSyCqimRXFRD4QSe3YjMjTJJc5VUk3bv8_R0"
            options={munchenLocation}
          />
          <ContactMapCard
            title={t('sections.contact.card3.title')}
            street={t('sections.contact.card3.street')}
            postCode={t('sections.contact.card3.postCode')}
            mapKey="AIzaSyCqimRXFRD4QSe3YjMjTJJc5VUk3bv8_R0"
            options={gottingenLocation}
          />
        </InnerWrapper>
      </ContentContainer>
    </Wrapper>
  )
}

ContactContent.propTypes = {}
ContactContent.defaultProps = {}

export default ContactContent
